<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.transactions.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'Transactions')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <!--                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>-->
              </div>
            </CCol>
          </div>
        </CCardHeader>

        <CCardBody>

          <v-server-table :columns="columns" url="/transactions/list" :options="options" ref="myTable"
                          @loaded="exports(self, 'Excel', module, 'Transactions')"
          >
            <template #razorPayStatus="data">
              <CBadge :color="statusBudget(data.row.razorPayStatus)">{{ data.row.razorPayStatus }}</CBadge>
            </template>

            <template #isFuture="data">
              <CBadge :color="statusBudget(data.row.isFuture)">{{ data.row.isFuture }}</CBadge>
            </template>

            <template #orderStatus="data">
              <CBadge :color="statusBudget(data.row.orderStatus)">{{ data.row.orderStatus }}</CBadge>
            </template>
            <template #createdAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
            </template>
            <template #actions="data">
              <CButton
                  :color="data.row.orderColor"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="rowClicked(data.row.orderId)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  transactions,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "TransactionItem",
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      color: 'success',
      selectedColumns: [],
      iAmUser: "",
      userId: "",
      listUrl: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: transactions,
      transactions: {},
      columns: [
        'type',
        'company',
        "orderId",
        "purchase",
        "finalAmount",
        'razorPayStatus',
        'orderStatus',
        'attempts',
        "isFuture",
        'createdAt',
        "actions",
      ],
      data: [],
      options: {
        headings: {
          type: this.$lang.transactions.table.type,
          company: this.$lang.transactions.table.company,
          orderId: this.$lang.transactions.table.orderId,
          razorpayPaymentId: this.$lang.transactions.table.razorpayPaymentId,
          purchase: this.$lang.transactions.table.purchase,
          finalAmount: this.$lang.transactions.table.amount,
          createdAt: this.$lang.transactions.table.createdAt,
          razorPayStatus: this.$lang.transactions.table.razorPayStatus,
          orderStatus: this.$lang.transactions.table.orderStatus,
          attempts: this.$lang.transactions.table.attempts,
          isFuture: this.$lang.transactions.table.isFuture,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [
          "createdAt",
        ],
        filterable: [],
      },
    };
  },
  created() {
    // let self = this;
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Start
    // this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    self.userId = this.$route.params.user_id;
    //Filter Section
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {

    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    applyFilter() {
      let self = this;
      self.selectedSkills = [];
      if (this.transactions.postTypeId != null) {
        //  postTypeId = this.transactions.postTypeId.value;
      }
      const reqObj = {
        //  postType: postTypeId,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();

    },

    resetFilter() {
      //     this.transactions.postTypeId = null;
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    setAlert(value) {
      this.showAlert = value;
    },

    postViewTableRow(item, userId) {
      this.$router.push({path: `posts/view/${item}/${userId}`});
    },
    editTableRow(item) {
      this.$router.push({path: `transactions/edit/${item}`});
    },
    rowClicked(item, index) {
      this.$router.push({path: `transactions/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
